import React from 'react'
import PrivateNoteTextAreaProperties from '../../types/PrivateNoteTextAreaProperties'
import {useStyles} from './PrivateNoteTextAreaStyles'

const PrivateNoteTextArea = (props: PrivateNoteTextAreaProperties) => {

    const classes = useStyles()

    return (

        <textarea
            ref={props.textareaRef}
            readOnly={!props.editMode}
            className={props.editMode ? classes.textareaEdit : classes.textarea}
            placeholder="Click 'Edit' to add a private note..."
            onClick={props.onEdit}
            value={props.privateNoteText}
            onChange={props.onTextareaChange}
            onKeyUp={props.onKeyUp}
        />
    )
}

export default PrivateNoteTextArea