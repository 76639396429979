import React from 'react'
import { PrivateNoteFooterProperties } from '../../types/PrivateNoteFooterProperties'
import FooterText from './FooterText'

const PrivateNoteFooter = (props: PrivateNoteFooterProperties) => (
    <div className="pn-footer-area">
        { props.editMode
        ? <>
            <button className="mod-primary" onClick={props.onSave}>Save</button>
            <button className="pn-cancel-button" onClick={props.onCancel}>&times;</button>
            <FooterText/>
        </>
        : <>
            <button className="pn-edit-button" onClick={props.onEdit}>Edit</button>
            <FooterText/>
        </> }
    </div>
)

export default PrivateNoteFooter