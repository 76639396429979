import { useEffect } from 'react'
import { PageView, initializeCapabilities } from 'trello-shared-resources'
import {showDarkThemeNotSupportedMessage} from 'trello-shared-resources/dist/services/DarkThemeService'

/* 
    The ConnectorView is used to tell Trello what capabilities your 
    app should have. Use this Connector to add new menu items, buttons
    and other similar capabilities.
*/
const ConnectorView = () => {

    useEffect(() => {
        PageView("PowerUp Connector")
    },[])

    const capabilities = {
        'card-back-section': (trelloContext: any) => {
            showDarkThemeNotSupportedMessage(trelloContext)
            return {
                title: 'Private Notes',
                icon: './icons/pn-logo.svg',
                content: {
                    type: 'iframe',
                    url: trelloContext.signUrl('./index.html?apptype=privatenotes'),
                    height: Number(process.env.REACT_APP_TRELLO_APP_IFRAME_SIZE_STANDARD)
                }
            }
        },
    }

    initializeCapabilities(capabilities)
    return null
}

export default ConnectorView