import React, { useContext } from 'react'
import { Skeleton } from 'antd'
import { LicenseDetailsContext } from 'trello-shared-resources/dist/index'
import PrivateNote from './PrivateNote'

/**
 * Wrapper component displaying a loading view while the license details are being populated.
 */
const PrivateNoteDisplay = () => {

    const licenseDetailsContext = useContext(LicenseDetailsContext)

    return ( licenseDetailsContext.isLoading
        ? <Skeleton active={true} />
        : <PrivateNote licenseDetailsContext={licenseDetailsContext}/>
    )
}

export default PrivateNoteDisplay