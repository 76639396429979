import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles({
        textarea: {
            backgroundColor: 'transparent !important',
            color: '#172b4d !important',
            resize: 'none',
            border: 'none !important',
            minHeight: `${process.env.REACT_APP_TRELLO_APP_TEXTAREA_MIN_HEIGHT} !important`,
            maxHeight: `${process.env.REACT_APP_TRELLO_APP_TEXTAREA_MAX_HEIGHT} !important`,
            boxShadow: 'none !important',
            marginBottom: '0 !important',
            '&:focus:placeholder': {
                color: 'grey !important'
            },
            '&::placeholder': {
                color: '#172b4d !important'
            },
            '&:placeholder-shown': {
                backgroundColor: 'rgba(9,30,66,.04) !important'
            },
            '&:hover': {
                backgroundColor: 'rgba(9,30,66,.13) !important'
            }
        },
        textareaEdit: {
            backgroundColor: 'white !important',
            color: '#172b4d !important',
            resize: 'none',
            borderColor: '#528CBB !important',
            borderWidth: '2px !important',
            borderStyle: 'solid !important',
            minHeight: `${process.env.REACT_APP_TRELLO_APP_TEXTAREA_EDIT_MIN_HEIGHT} !important`,
            maxHeight: `${process.env.REACT_APP_TRELLO_APP_TEXTAREA_EDIT_MAX_HEIGHT} !important`,
            boxShadow: 'none !important',
            marginBottom: '0 !important'
        }
    }
)


export {useStyles}
