import React, {ChangeEvent, KeyboardEvent, MouseEvent, useEffect, useRef, useState} from 'react'
import {TrackActionEvent} from 'trello-shared-resources/dist'
import {renderTrelloContextMissing} from 'trello-shared-resources/dist/components/powerUp/ModalOutputs'
import PrivateNoteTextArea from './PrivateNoteTextArea'
import PrivateNoteFooter from './PrivateNoteFooter'
import PrivateNoteErrorMessage from './PrivateNoteErrorMessage'
import {getPrivateNote, setPrivateNote} from '../../modules/Persistence'
import PrivateNoteCollapsed from './PrivateNoteCollapsed'
import ErrorStep from "trello-shared-resources/dist/components/onboarding/error/ErrorStep";

/**
 * Container component orchestrating state, context and handler integration.
 */
const PrivateNote = (props: { licenseDetailsContext: any }) => {

    const licenseDetailsContext = props.licenseDetailsContext
    const textareaRef = useRef<HTMLTextAreaElement>(null)
    const [editMode, setEditMode] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [privateNoteText, setPrivateNoteText] = useState('')
    const [privateNoteShowed, setPrivateNoteShowed] = useState(false)
    const trelloIframeContext = licenseDetailsContext.trelloIframeContext
    const context = trelloIframeContext ? trelloIframeContext.getContext() : undefined

    const smallIframeSize: number = Number(process.env.REACT_APP_TRELLO_APP_IFRAME_SIZE_SMALL)
    const standardIframeSize: number = Number(process.env.REACT_APP_TRELLO_APP_IFRAME_SIZE_STANDARD)
    const bigIframeSize: number = Number(process.env.REACT_APP_TRELLO_APP_IFRAME_SIZE_BIG)
    const authorizationOrLicenseframeSize: number = Number(process.env.REACT_APP_TRELLO_APP_IFRAME_SIZE_UNAUTHORIZED_UNLICENSED)

    useEffect(() => {
        const updatePrivateNoteAsync = async () => {
            setPrivateNoteText(await getPrivateNote(trelloIframeContext))
        }
        updatePrivateNoteAsync()
    }, [trelloIframeContext])

    const onTextareaChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setPrivateNoteText(event.target.value)
    }

    const onSave = async (event: MouseEvent<HTMLButtonElement>) => {
        await setPrivateNote(trelloIframeContext, privateNoteText).catch((error) => {
            setErrorMessage(
                error.toString().includes('PluginData length of 4096 characters exceeded') ?
                    `You've exceeded the maximum character count, and your text is not saved. Please edit and resave or your entry will be lost.` : error.message
            )
        })
        setEditMode(false)
        trelloIframeContext.sizeTo('#privateNoteRoot')
        TrackActionEvent('Button Click', context, {
            card_id: context.card,
            button: 'Save'
        })
    }

    const onEdit = (event: MouseEvent<HTMLElement>) => {
        textareaRef.current!.focus()
        setEditMode(true)
        setErrorMessage('')
        trelloIframeContext.sizeTo(bigIframeSize)
        TrackActionEvent('Button Click', context, {
            card_id: context.card,
            button: 'Edit'
        })
    }

    const onCancel = (event: MouseEvent<HTMLButtonElement>) => {
        cancelEdit()
        TrackActionEvent('Button Click', context, {
            card_id: context.card,
            button: 'Cancel'
        })
    }

    const onKeyUp = (event: KeyboardEvent) => {
        if (event.keyCode === 27) {
            cancelEdit()
            TrackActionEvent('Button Click', context, {
                card_id: context.card,
                button: 'scape_key'
            })
        }
    }

    const cancelEdit = async () => {
        setPrivateNoteText(await getPrivateNote(trelloIframeContext))
        setEditMode(false)
        trelloIframeContext.sizeTo(standardIframeSize)
    }

    if (!licenseDetailsContext.trelloIframeContext) {
        return renderTrelloContextMissing()
    }

    if (!licenseDetailsContext.isAuthorized || !licenseDetailsContext.isLicensed()) {
        licenseDetailsContext.smallErrorMessageWindow = true

        if ((!licenseDetailsContext.isAuthorized || !licenseDetailsContext.isLicensed()) && !privateNoteShowed) {
            trelloIframeContext.sizeTo(smallIframeSize)
            return <PrivateNoteCollapsed textButton="Show details"
                                         displayButtonHandler={() => {
                                             setPrivateNoteShowed(true)
                                             trelloIframeContext.sizeTo(authorizationOrLicenseframeSize)
                                         }}
            />
        }

        return (
            <>
                <PrivateNoteCollapsed textButton="Hide details" displayButtonHandler={() => {
                    setPrivateNoteShowed(false)
                    trelloIframeContext.sizeTo(smallIframeSize)
                }}/>

                <ErrorStep licenseDetails={licenseDetailsContext}/>
            </>
        )
    }

    return (
        <div id='privateNoteRoot'>
            <PrivateNoteTextArea
                editMode={editMode}
                privateNoteText={privateNoteText}
                onEdit={onEdit}
                onTextareaChange={onTextareaChange}
                textareaRef={textareaRef}
                onKeyUp={onKeyUp}
            />
            <PrivateNoteFooter
                editMode={editMode}
                onSave={onSave}
                onCancel={onCancel}
                onEdit={onEdit}
            />
            <PrivateNoteErrorMessage errorMessage={errorMessage}/>
        </div>
    )
}

export default PrivateNote