import React, { useEffect } from 'react'
import { PageView, LicenseDetailsProvider } from 'trello-shared-resources/dist/index'
import PrivateNoteDisplay from '../components/privatenote/PrivateNoteDisplay'
import '../css/power-up.css'

/**
 * The top level Private Note 'view'. This wraps the app functionality in the required licensing provider.
 */
const LicensedPrivateNote = () => {

    useEffect(() => {
        PageView('PrivateNote')
    }, [])

    return (
        <LicenseDetailsProvider>
            <PrivateNoteDisplay/>
        </LicenseDetailsProvider>
    )
}

export default LicensedPrivateNote