import {makeStyles} from "@material-ui/core/styles"

const useStyles = makeStyles( {
        button: {
            marginBottom: '10px'
        }
    }
)


export { useStyles }
