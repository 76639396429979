import React from 'react'
import {PrivateNoteWrappedProperties} from '../../types/PrivateNoteWrappedProperties'
import Button from '@atlaskit/button'
import {useStyles} from './PrivateNoteCollapsedStyles'

/**
 * Component is showed when a user has not a license or he is unauthorized
 * @param props
 *      displayButtonHandler function to handle event click button
 *      textButton text button to show
 * */
const PrivateNoteCollapsed = (props: PrivateNoteWrappedProperties) => {
    const classes = useStyles()
    return (<Button className={classes.button} onClick={props.displayButtonHandler}>{props.textButton}</Button>)
}

export default PrivateNoteCollapsed