import {Scope, TrelloContext, Visibility} from 'trello-shared-resources/dist'

/**
 * Key used in client library calls to set and retrieve data.
 * Must not be changed so that the legacy app data continues to work.
 */
const DATA_KEY = 'private-notes-key'

/**
 * Get the private note data for the current card.
 * 
 * @param trelloContext The context used to retrieve the data.
 */
export const getPrivateNote = (trelloContext: TrelloContext): Promise<any> => {
    return trelloContext.get!(Scope.CARD, Visibility.PRIVATE, DATA_KEY)
}

/**
 * Set the private note data for the current card.
 *
 * @param trelloContext The context used to set the data.
 * @param privateNote The value of the data.
 *
 * @return promise Contains the result of the set action
 */
export const setPrivateNote = (trelloContext: any, privateNote: string): Promise<any> => {
    if(!trelloContext.memberCanWriteToModel(Scope.CARD)) {
        return Promise.reject(new Error('You don\'t have permission to store notes on this card'))
    }
    return trelloContext.set!(Scope.CARD, Visibility.PRIVATE, DATA_KEY, privateNote)
}